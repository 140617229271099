import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import TableMain from "../../components/Common/table/TableMain";
import { useState } from "react";

const Approve = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortOptions, setSortOptions] = useState({
    sortBy: "",
    direction: "",
  });

  const statement = ApiHook.CallApproveStatement(currentPage, itemsPerPage);
  console.log("statement", statement?.data);
  const headers = ["username", "amount", "email", "File", "Action"];
  return (
    <>
      <div data-tut="dashboard" className="page_head_top">
        {t("approveUsers")}
      </div>
      <div className="joining_Teammbr_section">
        <div className="ewallet_table_section">
          <div className="ewallet_table_section_cnt">
            <div className="table-responsive min-hieght-table">
              <TableMain
                headers={headers}
                data={statement?.data?.rows}
                startPage={1}
                currentPage={currentPage}
                totalPages={statement?.data?.totalPages}
                setCurrentPage={setCurrentPage}
                type={"approve"}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                setEpinSortOptions={setSortOptions}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Approve;
