import React, { useState } from "react";
import "../../assests/css/notification.css";
import { useTranslation } from "react-i18next";

const Notification = () => {
  const { t } = useTranslation();
  const [isFirstVisible, setIsFirstVisible] = useState(true);
  const [isSecondVisible, setIsSecondVisible] = useState(true);

  const handleFirstClose = () => {
    setIsFirstVisible(false);
  };

  const handleSecondClose = () => {
    setIsSecondVisible(false);
  };

  return (
    <>
      {isFirstVisible && (
        <div className="notification">
          <p>{t("notificationWhatsappLink")}</p>
          <button className="close-btn" onClick={handleFirstClose}>
            ×
          </button>
        </div>
      )}

      {isSecondVisible && (
        <div className="notification">
          <p>{t("registrationWhatsappLink")}</p>
          <button className="close-btn" onClick={handleSecondClose}>
            ×
          </button>
        </div>
      )}
    </>
  );
};

export default Notification;
