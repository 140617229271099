import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "../../../assests/css/board.css";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Loader from "react-js-loader";

const TreeNode = ({ node, onNodeHover, onNodeLeave, image }) => {
  const { t } = useTranslation();

  const nodeRef = useRef(null);

  const handleMouseEnter = () => {
    const rect = nodeRef.current.getBoundingClientRect();
    onNodeHover(node.details, rect);
  };
  console.log({ image });
  return (
    <li className={node.position}>
      <a
        ref={nodeRef}
        href="#"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={onNodeLeave}
      >
        <div style={{ position: "relative" }}>
          <img
            style={{ width: "80px", height: "80px" }}
            src={
              image != ""
                ? image
                : "http://192.168.10.52:1660/assets/images/board.png"
            }
            alt={node.name}
          />
          <p
            style={{
              position: "absolute",
              top: "36%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: node.payment_status == 0 ? "red" : "green",
              width: "80px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "8ch",
            }}
          >
            {node.name}
          </p>
        </div>
      </a>
      {node.children && node.children.length > 0 && (
        <ul>
          {node.children.map((child) => (
            <TreeNode
              key={child.id}
              node={child}
              onNodeHover={onNodeHover}
              onNodeLeave={onNodeLeave}
              image={image}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

TreeNode.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.object),
    details: PropTypes.object,
  }).isRequired,
  onNodeHover: PropTypes.func.isRequired,
  onNodeLeave: PropTypes.func.isRequired,
};

const BoardTreeComponent = ({ treeData, currentBoardId, image }) => {
  const queryClient = useQueryClient();
  const updateApprove = ApiHook.CallApproveBoardTree();
  const updateBoardUser = (body) => {
    updateApprove.mutate(body, {
      onSuccess: (res) => {
        if (res.status) {
          queryClient.invalidateQueries({ queryKey: ["get-board-tree"] });
          toast.success(t(res?.data));
        } else {
          toast.error(
            res?.description
              ? t(res?.description)
              : t("errorOccuredPleaseTryAgain")
          );
        }
      },
    });
  };

  const [parentId, setParentId] = useState(treeData?.id ? treeData?.id : null);
  const { t } = useTranslation();
  const [tooltip, setTooltip] = useState(null);
  console.log(tooltip, "tooltip");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef(null);
  const tooltipTimeoutRef = useRef(null);

  const updateTooltipPosition = (rect) => {
    if (tooltipRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const offset = 10;
      let tooltipX =
        rect.left + window.scrollX + rect.width / 2 - tooltipRect.width / 2;
      let tooltipY = rect.top + window.scrollY - tooltipRect.height - offset;

      // Ensure tooltip does not go off-screen
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      if (tooltipX < 0) tooltipX = 0;
      if (tooltipY < 0) tooltipY = rect.bottom + window.scrollY + offset; // Show below if not enough space above
      if (tooltipX + tooltipRect.width > viewportWidth)
        tooltipX = viewportWidth - tooltipRect.width;
      if (tooltipY + tooltipRect.height > viewportHeight)
        tooltipY = viewportHeight - tooltipRect.height;

      setTooltipPosition({ x: tooltipX, y: tooltipY });
    }
  };

  const showTooltip = (content, rect) => {
    setTooltip(content);
    updateTooltipPosition(rect);
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
  };

  const hideTooltip = () => {
    tooltipTimeoutRef.current = setTimeout(() => {
      setTooltip(null);
    }, 300); // Delay hiding the tooltip
  };

  const handleTooltipMouseEnter = () => {
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
  };

  const handleTooltipMouseLeave = () => {
    hideTooltip();
  };

  useEffect(() => {
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };
  }, []);

  if (updateApprove.isLoading) {
    return (
      <div className="centered-loader">
        <Loader
          type="bubble-scale"
          bgColor={"#954cea"}
          color={"#ffffff"}
          size={50}
        />
      </div>
    );
  }

  return (
    <div className="board_tree">
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          position: "absolute",
          top: "20px",
          width: "63%",
          transform: "translate(-50%, -50%)",
          left: "50%",
        }}
      >
        <span className="span-title">ALPHA</span>
        <span className="span-title">OMEGA</span>
        <span className="span-title" style={{ opacity: 0 }}>
          OMEGA
        </span>
        <span className="span-title">BLESSED</span>
        <span className="span-title" style={{ opacity: 0 }}>
          OMEGA
        </span>
        <span className="span-title">OMEGA</span>
        <span className="span-title">ALPHA</span>
      </div>
      {treeData ? (
        <ul>
          <TreeNode
            node={treeData}
            onNodeHover={showTooltip}
            onNodeLeave={hideTooltip}
            image={image}
          />
        </ul>
      ) : (
        <p>No data available</p>
      )}
      {tooltip && (
        <div
          ref={tooltipRef}
          id="tooltip"
          style={{
            display: "block",
            position: "fixed",
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
            backgroundColor: "#f2f2f2",
            borderRadius: "10px",
            padding: "15px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            width: "350px",
            fontFamily: "Arial, sans-serif",
            zIndex: 1000,
          }}
          onMouseEnter={handleTooltipMouseEnter}
          onMouseLeave={handleTooltipMouseLeave}
        >
          <h4 id="tooltip-name" style={{ color: "red", textAlign: "center" }}>
            {tooltip.name}
          </h4>
          <p
            id="tooltip-fullname"
            style={{ color: "red", textAlign: "center" }}
          >
            {`${tooltip.dataValues.name} ${tooltip.dataValues.secondName}`}
          </p>
          <p id="tooltip-email" style={{ color: "red", textAlign: "center" }}>
            {tooltip.email}
          </p>
          <p id="tooltip-phone" style={{ color: "red", textAlign: "center" }}>
            {tooltip.dataValues.mobile}
          </p>
          {tooltip.dataValues.bitcoinAddress &&
            parentId == tooltip.board_leader && (
              <a
                id="tooltip-whatsapp"
                href={tooltip.dataValues.bitcoinAddress} // Use dataValues to access the address
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "red",
                  textDecoration: "none",
                  cursor: "pointer", // This will change the cursor to a pointer when hovering
                }}
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                  alt="WhatsApp"
                  style={{
                    width: "25px",
                    height: "25px",
                    marginRight: "10px",
                  }}
                />
                {t("communicateToPay")}
              </a>
            )}
          {tooltip.dataValues.bitcoinAddress &&
            parentId == tooltip.board_leader &&
            tooltip.payment_status == 0 && (
              <button
                style={{
                  backgroundColor: "#4CAF50", // Green background
                  border: "none",
                  color: "white",
                  padding: "10px 20px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inline-block",
                  fontSize: "16px",
                  margin: "4px 2px",
                  cursor: "pointer",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  transition: "background-color 0.3s ease, transform 0.3s ease",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = "#45a049"; // Darker green on hover
                  e.currentTarget.style.transform = "scale(1.05)"; // Slightly enlarge the button
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = "#4CAF50"; // Revert to original green
                  e.currentTarget.style.transform = "scale(1)"; // Revert to original size
                }}
                onClick={() => {
                  let body = {
                    board_user_id: tooltip.dataValues.userId
                      ? Number(tooltip.dataValues.userId)
                      : null,
                    board_id: currentBoardId,
                  };
                  updateBoardUser(body);
                }}
              >
                Approve
              </button>
            )}
        </div>
      )}
      <div
        style={{
          width: "260px",
          height: "90px",
          position: "absolute",
          bottom: 0,
        }}
      >
        <img
          style={{ width: "260px", height: "90px" }}
          src={`/images/${currentBoardId}.png`}
          alt="Description"
        />
      </div>
    </div>
  );
};

BoardTreeComponent.propTypes = {
  treeData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.object),
    details: PropTypes.object,
  }),
};

export default BoardTreeComponent;
